import { useNavigate } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Accommodation.css";
import AccommodationData from "./AccommodationData";


export default function Accommodation() {
  const navigate = useNavigate();
  const resortData = AccommodationData();
  const handleResortClick = (propertyLink) => {
    // Redirect to the route of the selected resort
    navigate(`/accommodation-details/${propertyLink}`);
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      Previous
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      Next
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };
  return (
    <div className="accom-con">
      <ScrollToTop />
      <h2>Accommodation</h2>
      <div className="long-linebreak"></div>
      {resortData.map((accommodation, index) => (
        <div key={`accommodation-${index}`}>
          <div className="row accom-row">
            <div className="gallery-slide col-md-6">
              <Slider {...settings} key={`slider-${index}`}>
                {accommodation.images.map((image, imgIndex) => (
                  <div key={imgIndex}>
                    <img
                      className="d-block w-100"
                      src={image}
                      alt={`Slide ${imgIndex + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="accom-intro-box col-md-4">
              <h3 key={`name-${index}`}>{accommodation.name}</h3>
              <p key={`description-${index}`}>{accommodation.description}</p>
              <div className="long-linebreak line-gray"></div>
              <div className="map-moreinfo">
                <div className="row">
                  <div className="col-6 viewmap">
                    <a href={accommodation.mapLink} key={`viewmap-${index}`}>
                      view map
                    </a>
                  </div>
                  <div className="col-6 moreinfo">
                    <div
                      onClick={() =>
                        handleResortClick(accommodation.moreInfoLink)
                      }
                      key={`moreinfo-${index}`}
                    >
                      more info
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="price-box col-md-2">
              <div className="row">
                <p>From</p>
                <h1>{accommodation.price}</h1>
                <div
                  onClick={() => handleResortClick(accommodation.moreInfoLink)}
                  className="col-12"
                  key={`property-${index}`}
                >
                  <div className="property-button">
                    <h3>View Property</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="long-linebreak" key={`linebreak-${index}`}></div>
        </div>
      ))}
    </div>
  );
}
