import "./About.css";
import ScrollToTop from "./ScrollToTop";
import aboutus2 from "../img/aboutus2.jpg";
import aboutus3 from "../img/aboutus3.jpg";
import aboutus5 from "../img/aboutus5.jpg";


export default function About() {
  return (
    <div className="about">
      <ScrollToTop />
      <div className="stick-img"></div>
      <div className="container">
        <div className="row">
          <div className="tittle-box col-sm-6 col-md-4 col-lg-3" align="center">
            <h2>ABOUT US</h2>
            <div className="line-break line-break2"></div>
          </div>
        </div>

        <div className="mainbox">
          <div className="sub-tittle">Our Concept</div>
          <div className="row">
            <div className="col-lg-4">
              <div className="col-text">
                <div className="line-break line-break2"></div>
                Elderly care facilities in Thailand has greatly available for
                foreigners who are residents and offer huge value. Moreover for
                Thai culture younger generation always respect to the older
                generation, that means the nurses and nursing aids will give you
                the ones you love the best care and attention. Living in
                Thailand, your parents can live in comfort and receive personal
                care for a fraction of the cost they’d pay in your countries.
                The cold weather is another factor, the dark cold and long
                winter in Europe and USA are bad enough when you are young and
                fit. But when you are not young, your bones ache and your
                mobility limited who want to spend any more time trudging
                through snow? Would it be better to wake up in the bright
                tropical sunrises, were flowers bloom all the year, and green
                coconut trees are everywhere?
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="col-text">
                <div className="line-break line-break2"></div>A benefit to bring
                your parents to an assisted living facilities in Thailand is
                that they can enjoy a higher quality of life with more personal
                attention. Because of the lower operating costs, care facilities
                in Thailand are able to dedicate a greater of qualified nursing
                staff for resident. And instead of staying in a room at a boring
                nursing home, care facilities in Thailand make them more enjoy
                living in their own luxury villa. Group activities and
                excursions can also be much more interesting in Thailand than
                back in your country. Depression and boredom can come and go, so
                it’s no better way to support good mental health for the elderly
                than leave them in a stimulating environment. The enthusiastic
                care facilities for the elderly in Thailand can’t compared to
                the western countries due to the traditional way of taking care
                Thai tradition and culture.
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="col-text">
                <img
                  className="aboutus-img1"
                  src={aboutus2}
                  alt="4 elderly relaxing on the beach"
                />
                <img
                  className="aboutus-img2"
                  src={aboutus3}
                  alt="Wine on the beach"
                />
                <img
                  className="aboutus-img5"
                  src={aboutus5}
                  alt="elderly couple on the beach"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ">
              <div className="col-text">
                <div className="line-break line-break2"></div>
                CEC is an elderly care by medical doctors and nurses which long
                time professional experience from thonburi-chumphon hospital
                (chumvej hospital). We provide the wide range of medical care
                for elderly people base on the geriatric medicine of high
                standard, by the concept of the best service for the best people
                and the elderly take care the elderly. CEC is a part of TCH
                (Thonburi-Chumphon Hospital) by Chumvej hospital public company.
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="col-text">
                <div className="line-break line-break2"></div>
                CEC provide accommodations for many variety e.g. Five stars
                beach resorts, four stars resorts, villas in the marina or even
                in the high standard general hospital. CEC care compose of
                luxury high standard accommodation, nutrition healthy food of
                three meals and tea break, medical care of high standard
                geriatric medicine from overall recheck until daily monitoring
                chronic diseases or aging conditions subacute or acute medical
                problems by teamwork of experience medical personals of all
                levels.{" "}
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="col-text">
                <div className="line-break line-break2"></div>
                CEC was established by elderly people who understand elderly
                very well cause we understand very well and feel the same they
                feel. The concept of take care each other and respect them from
                working hard all their life that bring them to this age they
                deserved to have meaningful life satisfying life and very happy
                life. All of these we can give to our customers. CEC future aims
                to raise the highest level of medical care, expand and over more
                accommodations in this field and effectively utilize existing
                potential of man material and method (3m).
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="break-box"></div>
    </div>
  );
}
