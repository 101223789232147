import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    const scrollRoutes = ['/accommodation', '/chumphon', '/about', '/thonburi-chumphon-hospital', '/accommodation-details/nana-beach-resort', '/accommodation-details/chumphon-cabana-resort', '/accommodation-details/armonia-resort'];
    if (scrollRoutes.includes(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
}

export default ScrollToTop;