import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Accommodation from "./components/Accommodation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Chumphon from "./components/Chumphon";
import TCH from "./components/TCH";
import Contact from "./components/Contact";
import About from "./components/About";
import AccommodationDetails from "./components/AccommodationDetails";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/accommodation" element={<Accommodation />} />
          <Route
            path="/accommodation-details/:propertyLink"
            element={<AccommodationDetails />}
          />
          <Route path="/chumphon" element={<Chumphon />} />
          <Route path="/thonburi-chumphon-hospital" element={<TCH />} />
        </Routes>
      </BrowserRouter>
      <Contact />
      <Footer />
    </div>
  );
}
