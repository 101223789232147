import "./Header.css";
import logo from "../img/logoCEC.png";
import { Link, useMatch, useLocation } from "react-router-dom";
import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { BsDashLg } from "react-icons/bs";

function CustomLink({ to, children }) {
  const match = useMatch(to);

  return (
    <Link to={to} className={match ? "active" : ""}>
      {children}
    </Link>
  );
}

export default function Header({ anchorId }) {

  const location = useLocation();

  const [collapse, setCollapse] = useState(true);
  const handleMenuItemClick = () => {
    setCollapse(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToAnchor = (anchorId) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavLinkClick = (anchorId = null) => {
    if (anchorId) {
      // Scroll to the specified anchor after a brief delay
      setTimeout(() => {
        scrollToAnchor(anchorId);
      }, 100); // Adjust the delay time as needed
    } else {
      // Scroll to the top of the page for "HOME" link
      scrollToTop();
    }
  };

  const [isMenuVisible, setMenuVisibility] = useState(false);

  const toggleMenu = () => {
    setMenuVisibility(!isMenuVisible); // Toggle menu visibility on click
  };

  const handleMouseOver = () => {
    setMenuVisibility(true); // Show menu on hover
  };

  const handleMouseLeave = () => {
    setMenuVisibility(false); // Hide menu when mouse leaves
  };

  return (
    <div>
      <div className="nav-area">
        <div className="container">
          <div className="row row-nav">
            <Link to="/" onClick={() => handleNavLinkClick()}>
              <div className="logo-area">
                <div className="logo-con">
                  <img src={logo} alt="logo" />
                </div>
                <div className="logo-text">
                  <span className="t1">Chumvej Elderly Care</span>
                  <span className="t2">
                    <BsDashLg />
                    &nbsp; Retirement with Hospital Care &nbsp;
                    <BsDashLg />
                  </span>
                </div>
              </div>
            </Link>
            <nav className="mainnav">
              <ul>
                <li className="nav-item">
                  <CustomLink to="/" onClick={() => handleNavLinkClick()}>
                    HOME
                  </CustomLink>
                </li>
                <li className="nav-item">
                  <CustomLink
                    to="/about"
                  >
                    ABOUT
                  </CustomLink>
                </li>
                <li className={location.pathname === '/accommodation' ? 'active' : ''}>
                  <Link className="nav-item"
                    to="/"
                    onClick={() => handleNavLinkClick("accom-anchor")}
                  >
                    ACCOMMODATION
                  </Link>
                  
                </li>
                <li className="">
                  <Link
                    to="/"
                    onClick={() => handleNavLinkClick("services-anchor")}
                  >
                    SERVICES
                  </Link>
                </li>
                <li>
                  <div
                    className="nav-item moreItem"
                    onClick={toggleMenu}
                    onMouseOver={handleMouseOver}
                  >
                    More
                  </div>
                </li>
              </ul>
            </nav>
            <div className="booknow-button-nav">
              <Link to="/" onClick={() => handleNavLinkClick("booknow-anchor")}>
                <span className="booknow-button">BOOK NOW</span>
              </Link>
            </div>
            <div
              onClick={() => setCollapse(!collapse)}
              className="mobile-menu col-1"
            >
              {
                <div className="mobile-menu-button">
                  <FiMenu />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {isMenuVisible && (
        <div className="moreNav" onMouseLeave={handleMouseLeave}>
          <div>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("location-anchor")}
              className="nav-item"
              activeclassname="active"
            >
              <li>LOCATION</li>
            </Link>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("healthcare-anchor")}
              className="nav-item"
              activeclassname="active"
            >
              <li>HEALTH CARE</li>
            </Link>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("things-to-do-anchor")}
              className="nav-item"
              activeclassname="active"
            >
              <li>SEE & DO</li>
            </Link>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("contact-anchor")}
              className="nav-item"
              activeclassname="active"
            >
              <li>CONTACT</li>
            </Link>
          </div>
        </div>
      )}
      {!collapse && (
        <div className="mobileDropdown">
          <div className="collapse-menu-container">
            <Link to="/">
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Home
              </button>
            </Link>

            <Link to="/about">
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                About
              </button>
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("accom-anchor-mobile")}
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Accommodation
              </button>{" "}
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("services-anchor-mobile")}
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Services
              </button>
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("location-anchor-mobile")}
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Location
              </button>{" "}
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("healthcare-anchor-mobile")}
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Health Care
              </button>{" "}
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("things-to-do-anchor-mobile")}
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                See & Do
              </button>
            </Link>

            <Link
              to="/"
              onClick={() => handleNavLinkClick("contact-anchor-mobile")}
              className="nav-item"
              activeclassname="active"
            >
              <button className="mobileMenuGo" onClick={handleMenuItemClick}>
                Contact
              </button>{" "}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
