import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

import "./Contact.css";
function handleFocus() {
  document.querySelectorAll(".form-input").forEach((input) => {
    input.addEventListener("focus", () => {
      input.parentNode.querySelector(".form-label").style.top = "1px";
      input.parentNode.querySelector(".form-label").style.fontSize = "12px";
    });
    input.addEventListener("blur", () => {
      if (input.value === "") {
        input.parentNode.querySelector(".form-label").style.top = "10px";
        input.parentNode.querySelector(".form-label").style.fontSize = "18px";
      }
    });
  });
}
export default function Contact() {
  useEffect(() => {
    handleFocus();
  }, []);

  //send email
  const form = useRef();

  function sendEmailContact(e) {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_bo8sphj",
          "template_ms76fbl",
          form.current,
          "I0f3HWdLW8iNmu4CG"
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              icon: "success",
              title: "Message Sent Successfully",
            });
          },
          (error) => {
            console.log(error.text);
            Swal.fire({
              icon: "error",
              title: "Failed to Send Message",
              text: "Oops, something went wrong!",
            });
          }
        );
    }
  }

  return (
    <div className="contact-area">
      <div className="container contact-container">
        <h3>CONTACT US</h3>
        <div className="line-break line-break2"></div>
        <p>121 Moo3 Wangpai Muang Chumphon 86000</p>
        <p>Tel: +66816065833 (English, German, ltalian, Thai)</p>
        <form
          className="row contact-form"
          ref={form}
          onSubmit={sendEmailContact}
        >
          <div className="form-group col-md-6">
            <label className="form-label" htmlFor="name">
              Name
            </label>
            <input className="form-input" type="text" name="name" required />
          </div>
          <div className="form-group col-md-6">
            <label className="form-label" htmlFor="email">
              E-mail
            </label>
            <input className="form-input" type="email" name="email" required />
          </div>
          <div className="form-group col-12">
            <label className="form-label" htmlFor="subject">
              Subject
            </label>
            <input className="form-input" type="text" name="subject" required />
          </div>
          <div className="form-group form-group-message col-12">
            <label className="form-label" htmlFor="message">
              Message
            </label>
            <textarea className="form-input" name="message" required></textarea>
          </div>
          <div className="form-group col-md-6">
            <input type="reset" value="RESET"></input>
          </div>
          <div className="form-group col-md-6">
            <input type="submit" value="SUBMIT"></input>
          </div>
        </form>
        <div id="break-box-clear"></div>
      </div>
    </div>
  );
}
