import React, { useState } from "react";
import "./Slider.css";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../img/slider1.jpg";
const data = [
  {
    id: 1,
    image: require("../img/slider1.jpg"),
    caption: (
      <div className="slide1text">
        <span>Enjoy your retirement</span>
        <br />
        <span className="slide1text2">
          with the reassurance of professional health care
        </span>
      </div>
    ),
  },
  {
    id: 2,
    image: require("../img/slider2.jpg"),
    caption: (
      <div className="slide2container">
        <div className="slide2textcon">
          <span className="slide2text">
            Affordable Beach Town in the south of Thailand
          </span>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    image: require("../img/slider3.jpg"),
    caption: (
      <div className="slide2textcon">
        <span className="slide3text">
          Home Visit <br /> House Call
          <br /> Physical Therapist
          <br />
          Nurse Aid
        </span>
      </div>
    ),
  },
];
export default function Slider() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {data.map((slide) => {
          return (
            <Carousel.Item key={slide.id}>
              <img
                className="d-block w-100"
                src={slide.image}
                alt="slider"
              />
              <Carousel.Caption>
                <div>{slide.caption}</div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className="slide1">
        <img src={slide1} alt="slider1"/>
      </div>
    </div>
  );
}
