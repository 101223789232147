import Slider from "./Slider";
import { SlArrowRight } from "react-icons/sl";
import "./Home.css";
import houseIcon from "../img/accom.png";
import hospitalIcon from "../img/hospital_icon.png";
import ambulanceIcon from "../img/ambulance_icon.png";
import laundryIcon from "../img/laundry_icon.png";
import visaIcon from "../img/visa_icon.png";
import mealIcon from "../img/meal_icon.png";
import nnb from "../img/nnb.jpg";
import nnbPoolMain from "../img/nnbpool-main.jpg";
import cp from "../img/cp.jpg";
import tch from "../img/tch.jpg";
import boatIcon from "../img/boat.png";
import fishingIcon from "../img/fishing.svg";
import raftingIcon from "../img/rafting.svg";
import massageIcon from "../img/massage.svg";
import divingIcon from "../img/diving.svg";
import caveIcon from "../img/cave.png";
import { Link } from "react-router-dom";
import BookNowForm from "./BookNowForm";

export default function Home() {
  return (
    <div>
      <Slider />
      <div className="main-container">
        <div id="break-box"></div>
        <div className="first-box">
          <h2>Live in Affordable Beach Town</h2>
          <div className="line-break line-break1"></div>
          <div id="services-anchor"></div>
          <h4>
            Oversea retirement beach town in a south of Thailand with seaview
            and an affordable cost of living
            <br /> Enjoy your retirement with the reassurance of professional
            health care!
          </h4>
          <div id="services-anchor-mobile"></div>
          <h3>OUR SERVICES</h3>
          <div className="row icon-con">
            <div className=" col-sm-4">
              <img className="accom-icon" src={houseIcon} alt="house icon" />
              <h5>Accommodation</h5>
              <p>
                Hotels, Resorts, Seaside Resort,
                <br />
                Apartment, Villa
              </p>
            </div>
            <div className=" col-sm-4">
              <img
                className="medcare-icon"
                src={hospitalIcon}
                alt="hospital icon"
              />
              <h5>Medical Care</h5>
              <p>
                Home Visit, House Call, Physical
                <br />
                Therapist, Nurse Aid by request
              </p>
            </div>
            <div className=" col-sm-4">
              <img
                className="emergency-icon"
                src={ambulanceIcon}
                alt="emergency-icon"
              />
              <h5>Emergency Centre</h5>
              <p>
                24 hours Ambulance and Rescue
                <br />
                Boat in case of an Emergency
              </p>
            </div>

            <div className=" col-sm-4">
              <img className="visa-icon" src={visaIcon} alt="visa-icon" />
              <h5>Visa Application</h5>
              <p>
                Visa Assistance, Documents
                <br />
                Checklist, Online Application Form
              </p>
            </div>
            <div className=" col-sm-4">
              <img
                className="housekeeping-icon"
                src={laundryIcon}
                alt="housekeeping-icon"
              />
              <h5>Housekeeping</h5>
              <p>
                Serviced Room, Laundry Service,
                <br />
                Maintenance
              </p>
            </div>
            <div className=" col-sm-4">
              <img className="meal-icon" src={mealIcon} alt="meal-icon" />
              <h5>Meal Service</h5>
              <p>
                Healthy Food; European/Thai/
                <br />
                Seafood, Tea/Coffee break
              </p>
            </div>
          </div>
        </div>
        <div id="accom-anchor"></div>
        <div id="break-box"></div>
        <div id="accom-anchor-mobile"></div>
        <div className="row">
          <div className="col-md-4">
            <div className="green-box">
              <h1 className="box-text">
                Our Accommodation
                <br />
                <div id="line-break-box"></div>
              </h1>
            </div>
          </div>
          <div className="col-md-8">
            <div className="accom-img1">
              <img src={nnb} alt="nana beach resort" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div>
              <img src={nnbPoolMain} alt="nana beach resort" className="w100" />
            </div>
          </div>
          <div className="col-md-8">
            <div className="accom-text">
              <p className="box-text2">
                We have variety of accommodations such as seafront resorts,
                hotels, apartment, and villa. You will be able to breathe fresh
                air, wake up to the sea view, wonder in a garden, sit by the
                pool, go fishing, and many more activities in Chumphon (south of
                Thailand) area.
              </p>
              <div className="see-accom-button">
                <Link to="/accommodation">
                  SEE ACCOMMODATION{" "}
                  <span className="arrow-icon-thin">
                    <SlArrowRight />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div id="location-anchor"></div>
        </div>

        <div id="break-box"></div>
        <div id="location-box">
          <div className="row">
            <div id="location-anchor-mobile"></div>
            <div className="location-img col-md-8">
              <img src={cp} width="100%" alt="Chumphon" />
            </div>
            <div className="col-md-4">
              <div className="green-box">
                <h1 className="box-text">
                  Location
                  <br />
                  <div id="line-break-box"></div>
                </h1>
              </div>
            </div>
            <div className="accom-text ">
              <p className="box-text2">
                Chumphon is located in the south of Thailand, 467 km from
                Bangkok. With a 222-kilometre-long coastline and 44 islands, the
                Chumphon Archipelago, Chumphon has waterfalls, peaceful beaches,
                green forests, mangroves, and rivers. Chumphon has yet to have
                become overrun by mainstream tourism and still offers and old
                school charm long lost in many of Thailand’s other coastal
                provinces.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="see-accom-button text-center">
                    <Link to="/chumphon">
                      MORE ABOUT CHUMPHON{" "}
                      <span className="arrow-icon-thin">
                        <SlArrowRight />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="see-accom-button text-center">
                    <a href="https://www.google.com/maps/place/Chumphon/@10.3181891,98.5236066,9z/data=!3m1!4b1!4m5!3m4!1s0x3056058eace858d5:0x10223bc2c364ce0!8m2!3d10.4930496!4d99.1800199">
                      VIEW MAP{" "}
                      <span className="arrow-icon-thin">
                        <SlArrowRight />
                      </span>
                    </a>
                    <div id="healthcare-anchor"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="break-box"></div>
        <div id="healthcare-anchor-mobile"></div>
        <div className="tch">
          <div className="row">
            <div className="col-md-5">
              <img src={tch} alt="TCH" className="tch-img" />
            </div>
            <div className="col-md-7">
              <div className="green-box2 text-left">
                <h1>About Thonburi-Chumphon Hospital</h1>
                <div className="line-break line-break2"></div>
                <p>
                  TCH is a secondary hospital with 100 beds with over 20 years
                  experience behind us in the hospital services sector, we have
                  shown a commitment to the highest standards. This has allowed
                  us to achieve the hospital accreditation standard of HA 1. TCH
                  will take care of your health during your stay.
                </p>
                <div className="see-accom-button">
                  <Link to="/thonburi-chumphon-hospital">
                    MORE ABOUT TCH{" "}
                    <span className="arrow-icon-thin">
                      <SlArrowRight />
                    </span>
                  </Link>
                </div>

                <div id="things-to-do-anchor"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="break-box"></div>
        <div id="things-to-do-anchor-mobile"></div>
        <div className="first-box things-to-do-box">
          <h3>THINGS TO DO</h3>
          <div className="row">
            <div className="col-sm-4">
              <img
                className="to-do-icons boat-icon"
                src={boatIcon}
                alt="boatIcon"
              />
              <h5>Cruise</h5>
            </div>
            <div className="col-sm-4">
              <img
                className="to-do-icons"
                src={fishingIcon}
                alt="fishingIcon"
              />
              <h5>Fishing</h5>
            </div>
            <div className="col-sm-4">
              <img
                className="to-do-icons"
                src={raftingIcon}
                alt="raftingIcon"
              />
              <h5>Rafting</h5>
            </div>

            <div className="col-sm-4">
              <img
                className="to-do-icons"
                src={massageIcon}
                alt="massageIcon"
              />
              <h5>Spa & Massage</h5>
            </div>
            <div className="col-sm-4">
              <img className="to-do-icons" src={divingIcon} alt="divingIcon" />
              <h5>Diving & Snorkeling</h5>
            </div>
            <div className="col-sm-4">
              <img
                className="to-do-icons cave-icon"
                src={caveIcon}
                alt="caveIcon"
              />
              <h5>Cave Exploring</h5>
            </div>
          </div>

          <div id="booknow-anchor"></div>
        </div>
        <div id="break-box"></div>
        <div id="booknow-anchor-mobile"></div>
        <div className="container booknow-container">
          <div className="booking-content">
            <p>BOOK NOW</p>
            <div className="line-break line-break3"></div>
            <div className="booknow-con">
              <BookNowForm />
            </div>
          </div>
        </div>
        <div id="contact-anchor-mobile"></div>
        <div id="break-box"></div>
      </div>
    </div>
  );
}
