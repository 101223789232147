import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import "./CustomiseStayForm.css";

const checkboxData = [
  {
    name: "room-single",
    label: "Room single (mini villa)",
    price: "2,200 - 2,800 THB",
    checked: true,
    required: true,
    readOnly: true
  },
  {
    name: "lunch",
    label: "Lunch healthy meal; European / Thai Food",
    price: "300 THB",
    checked: true,
    required: true,
    readOnly: true

  },
  {
    name: "tea-coffee-break",
    label: "Tea / coffee break",
    price: "200 THB",
    checked: true,
    required: true,
    readOnly: true

  },
  {
    name: "dinner",
    label: "Dinner healthy meal; European / Sea Food",
    price: "500 THB",
    checked: true,
    required: true,
    readOnly: true

  },
  {
    name: "special-nursing-care",
    label: "Special nurse care (RN 8 hrs. per 1 shift)",
    price: "2,000 THB",
    required: false,
  },
  {
    name: "special-nursing-aid-care",
    label: "Special nursing aid care (qualify) per 8 hrs.",
    price: "1,000 THB",
    required: false,
  },
  {
    name: "physical-therapist",
    label: "Physical therapist (PT) 2-3 hrs. per course",
    price: "1,000 THB",
    required: false,
  },
  {
    name: "doctor-visit",
    label: "Doctor visit per time",
    price: "500 THB",
    required: false,
  },
  {
    name: "activities",
    label: "Activities (tour, cooking class, yoga, pool exercise)",
    price: "1,000 THB",
    required: false,
  },
  {
    name: "transportation",
    label: "Transportation (round trip) per time",
    price: "300 THB",
    required: false,
  },
];

export default function CustomiseStayForm() {
  const form = useRef();

  function sendEmailCustomiseStay(e) {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_bo8sphj",
          "template_ms76fbl",
          form.current,
          "I0f3HWdLW8iNmu4CG"
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              icon: "success",
              title: "Message Sent Successfully",
            });
          },
          (error) => {
            console.log(error.text);
            Swal.fire({
              icon: "error",
              title: "Failed to Send Message",
              text: "Oops, something went wrong!",
            });
          }
        );
    }
  }
  return (
    <div className="customise-con">
      <div className="title">
        <h2>Customise Your Stay</h2>
        <p>Items with a red star are compulsory</p>
        <div className="line-break line-break2"></div>
      </div>
      <div>
        <form ref={form} onSubmit={sendEmailCustomiseStay}>
          <div className="form-group col-12">
            <fieldset className="cares row" name="features">
              {checkboxData.map((checkbox, index) => (
                <React.Fragment key={index}>
                  <div className="col-1">
                    <input
                      type="checkbox"
                      name={checkbox.name}
                      checked={checkbox.checked}
                      required={checkbox.required}
                      readOnly={checkbox.readOnly}
                    />
                  </div>
                  <div className="col-11">
                    <label htmlFor={checkbox.name}>
                      {checkbox.required ? (
                        <span className="red">*</span>
                      ) : null}{" "}
                      {checkbox.label}{" "}
                      <span className="number-text">{checkbox.price}</span>
                    </label>
                  </div>
                </React.Fragment>
              ))}
              <div className="form-group-fix col-12">
                <label htmlFor="accom-choice" className="form-label-fix">
                  Accommodation
                </label>
                <input
                  className="form-input-fix"
                  type="text"
                  id="accom-list"
                  name="accom-list"
                />
                <select name="accom-list" id="accom-list">
                  <option value="">Select Accommodation</option>
                  <option value="Nana Beach">Nana Beach</option>
                  <option value="Chumphon Cabana Resort">
                    Chumphon Cabana Resort
                  </option>
                  <option value="Armonia Village">Armonia Village</option>
                </select>
              </div>
              <div className="form-group col-12">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  className="form-input form-input-email"
                  type="email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group col-12">
                <input type="submit" value="GET A QUOTE"></input>
              </div>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
  );
}
