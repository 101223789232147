import "./Chumphon.css";
import ScrollToTop from "./ScrollToTop";
import YouTube from "react-youtube";
import { FaPlane, FaTaxi } from "react-icons/fa";
import { FaTrainSubway, FaVanShuttle } from "react-icons/fa6";
import { IoMdBoat } from "react-icons/io";
import { SlArrowRight } from "react-icons/sl";

import chumphonAirport2 from "../img/chumphon airport2.jpg";
import byTrain from "../img/bytrain.jpg";
import bus1 from "../img/bus1.jpg";
import bus2 from "../img/bus2.jpg";
import boat1 from "../img/boat1.jpg";
import boat2 from "../img/boat2.jpg";
import taxi1 from "../img/taxi1.jpg";
import taxi2 from "../img/taxi2.jpg";
import { Link } from "react-router-dom";

export default function Chumphon() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToAnchor = (anchorId) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavLinkClick = (anchorId = null) => {
    if (anchorId) {
      // Scroll to the specified anchor after a brief delay
      setTimeout(() => {
        scrollToAnchor(anchorId);
      }, 100); // Adjust the delay time as needed
    } else {
      // Scroll to the top of the page for "HOME" link
      scrollToTop();
    }
  };

  // Replace with your YouTube video ID
  const videoId = "cgqDF2o0hn8";

  // Optional: You can also define player options
  const opts = {
    height: "250",
    width: "100%",
    playerVars: {
      autoplay: 0, // 0 or 1
      origin: "http://localhost:3000",
    },
  };

  // Handle when the video player is ready
  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo(); // For example, you can control the video playback
  };

  return (
    <div className="chumphon-page">
      <ScrollToTop />
      <div className="stick-img"></div>
      <div className="container">
        <div className="row">
          <div className="tittle-box col-sm-6 col-md-4 col-lg-3" align="center">
            <h2>CHUMPHON</h2>
            <div className="line-break line-break2"></div>
          </div>
        </div>
        <div className="mainbox">
          <div className="sub-tittle">
            About Chumphon
            <div className="line-break line-break2"></div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="col-text">
                <p>
                  Chumphon is a gateway to the south of Thailand, 467 km. from
                  Bangkok. One of Thailand’s most beautiful, yet lesser known,
                  with 222 km. long coastline and 44 islands, the Chumphon
                  Archipelago, Chumphon has waterfalls, peaceful beaches, green
                  forests, mangroves, and rivers. Chumphon has yet to have
                  become overrun by mainstream tourism and still offers and old
                  school charm long lost in many of Thailand’s other coastal
                  provinces.
                </p>
              </div>
            </div>
            <div className="col-md-5 ">
              <div className="col-vid">
                <YouTube videoId={videoId} opts={opts} onReady={onReady} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="sub-tittle">
              Getting there...
              <div className="line-break line-break2"></div>
            </div>
            <div className="col-md-7 col-tran-box">
              <div className="col-text">
                <div className="transport-row">
                  <Link
                    to="/chumphon"
                    className=" col-tran"
                    onClick={() => handleNavLinkClick("by-plane-anchor")}
                  >
                    <div className="tsp-icon plane">
                      <FaPlane />
                    </div>
                  </Link>
                  <Link
                    to="/chumphon"
                    className=" col-tran"
                    onClick={() => handleNavLinkClick("by-train-anchor")}
                  >
                    <div className="tsp-icon train">
                      <FaTrainSubway />
                    </div>
                  </Link>
                  <Link
                    to="/chumphon"
                    className=" col-tran"
                    onClick={() => handleNavLinkClick("by-bus-anchor")}
                  >
                    <div className="tsp-icon bus">
                      <FaVanShuttle />
                    </div>
                  </Link>
                  <Link
                    to="/chumphon"
                    className=" col-tran"
                    onClick={() => handleNavLinkClick("by-boat-anchor")}
                  >
                    <div className="tsp-icon boat">
                      <IoMdBoat />
                    </div>
                  </Link>
                  <Link
                    to="/chumphon"
                    className=" col-tran"
                    onClick={() => handleNavLinkClick("by-taxi-anchor")}
                  >
                    <div className="tsp-icon taxi">
                      <FaTaxi />
                    </div>
                  </Link>
                  <a
                    href="https://12go.asia/en/thailand/chumphon"
                    className="ticket-btn"
                  >
                    <span>Find Ticket</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-next-tran"></div>
          </div>
          <div id="by-plane-anchor"></div>
          <div className="col-text">By plane from Bangkok:</div>
          <div className="row center-row">
            <div className="col-md-7">
              <div className="col-table">
                <div className="plane-table">
                  <table>
                    <tbody>
                      <tr>
                        <th>Airlines</th>
                        <th>Destinations</th>
                        <th>Times</th>
                      </tr>
                      <tr>
                        <td>Nok Air</td>
                        <td>Bangkok (DMK) - Chumphon (CJM)</td>
                        <td>Early morning, Afternoon</td>
                      </tr>
                      <tr>
                        <td>AirAsia</td>
                        <td>Bangkok (DMK) - Chumphon (CJM)</td>
                        <td>Morning</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-text text-chumphon1">
                Chumphon Airport is located 30 km. north of Chumphon City in the
                Pathio District. The airport is located just five minutes away
                from the unspoilt beaches of Pathio and offers direct access to
                the extensive island archiapelago along the coastline of
                Chumphon. Flights from Bangkok take about 1 hours.
              </div>
            </div>
            <div className="col-md-5 ">
              <div className="col-img">
                <img src={chumphonAirport2} alt="chumphon route" />
              </div>
            </div>
            <div className="col-text col-12 text-chumphon2">
              Chumphon Airport is located 30 km. north of Chumphon City in the
              Pathio District. The airport is located just five minutes away
              from the unspoilt beaches of Pathio and offers direct access to
              the extensive island archiapelago along the coastline of Chumphon.
              Flights from Bangkok take about 1 hours.
            </div>
          </div>
          <div className="row center-row">
            <div className="col-md-7">
              <div id="by-train-anchor"></div>
              <div className="col-text">
                <p> By Train from Bangkok:</p>
                Trains from Bangkok to Chumphon leave from Hua Lamphong Station
                throughout the day. ​They leave at 8:05 am, 1 pm, 2:45 pm, 3:10
                pm, 3:35pm, 7:05 pm, 5:35 pm, 6:30 pm, 7:30 pm and 10:50 pm.They
                offer the most comfortable ride, depending on which ticket you
                book. For example, First class sleepers get you and a partner a
                private cabin which is air conditioned and suitable for a
                comfortable nights sleep if you take any of the night trains.
                Second class sleepers are also air-conditioned and provide beds
                that fold out of your seating upon request or in the evening.
                The trip takes between 7 and 9 hours.
              </div>
            </div>
            <div className="col-md-5">
              <div className="col-img">
                <img src={byTrain} alt="train chumphon" />
                <div id="by-bus-anchor"></div>
              </div>
            </div>
          </div>
          <div className="row center-row">
            <div className="col-md-7">
              <div className="col-text">
                <p> By Bus from Bangkok:</p>
                Bus tickets to Chumphon can be booked from Bangkok through
                several tour companies at Sai Tai Mai bus station, the Southern
                bus terminal in Bangkok. There are many types of buses including
                Express, VIP 35 seats, VIP 24 seats. The buses leave several
                times from 9:00 am to 10:00 pm. The trip takes under 7 hours to
                get to Chumphon including several stops at petrol stations.{" "}
              </div>
            </div>
            <div className="col-md-5">
              <div className="col-img">
                <div className="row row-2img">
                  <div className="col-lg-6">
                    <img src={bus1} alt="bus1" />
                  </div>
                  <div className="col-lg-6">
                    <img src={bus2} alt="bus2" />
                    <div id="by-boat-anchor"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row center-row">
            <div className="col-md-7">
              <div className="col-text">
                <p> By Ferry:</p>
                If you are travelling north from the southern islands of
                Thailand, Chumphon can be accessed via ferry from any of the
                ports on Koh Samui, Koh Tao and Koh Phangan. Koh Tao is
                connected to Chumphon by direct ferry. However, please note that
                the ferries’ departure times are subject to change and also
                subject to climate changes. In case of really bad weather, boats
                can be canceled for days and the night ferries are usually the
                first to be affected.
              </div>
            </div>
            <div className="col-md-5">
              <div className="col-img">
                <div className="row row-2img">
                  <div className="col-lg-6">
                    <img src={boat1} alt="ferry1" />
                  </div>
                  <div className="col-lg-6">
                    <img src={boat2} alt="ferry2" />
                    <div id="by-taxi-anchor"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row center-row">
            <div className="col-md-7">
              <div className="col-text">
                <p> By Private Taxi:</p>Private taxis are a great way to travel
                if you don’t wish to share your transportation with a bunch of
                strangers, and it’s the ultimate in convenience as they will
                take you right to the door of your place so you don’t have to
                worry about transfers or getting lost! With such convenience and
                comfort comes a high price – private taxis from Bangkok to
                Chumphon are the most expensive way to travel between these two
                cities. A minivan can hold 7 passengers while a van can hold up
                to 9, so divide the cost between you and it’s not as bad as it
                first sounds. The drive should take anywhere from 5.5 to 6.5
                hours.
              </div>
            </div>
            <div className="col-md-5">
              <div className="col-img">
                <div className="row row-2img">
                  <div className="col-lg-6">
                    <img src={taxi1} alt="taxi1" />
                  </div>
                  <div className="col-lg-6">
                    <img src={taxi2} alt="taxi2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="see-accom-button col-text">
              <Link
                to="/"
                onClick={() => handleNavLinkClick("things-to-do-anchor")}
              >
                Things to do in Chumphon{" "}
                <span className="arrow-icon-thin">
                  <SlArrowRight />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="break-box"></div>
    </div>
  );
}
