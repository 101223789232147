import "./BookNowForm.css";
import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function handleFocus() {
  document.querySelectorAll(".form-input").forEach((input) => {
    input.addEventListener("focus", () => {
      input.parentNode.querySelector(".form-label").style.top = "1px";
      input.parentNode.querySelector(".form-label").style.fontSize = "12px";
    });
    input.addEventListener("blur", () => {
      if (input.value === "") {
        input.parentNode.querySelector(".form-label").style.top = "10px";
        input.parentNode.querySelector(".form-label").style.fontSize = "18px";
      }
    });
  });
}
export default function BookNowForm() {
  useEffect(() => {
    handleFocus();
  }, []);

  //send email
  const form = useRef();

  function sendEmailBooking(e) {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          "service_bo8sphj",
          "template_ms76fbl",
          form.current,
          "I0f3HWdLW8iNmu4CG"
        )
        .then(
          (result) => {
            console.log(result.text);
            Swal.fire({
              icon: "success",
              title: "Message Sent Successfully",
            });
          },
          (error) => {
            console.log(error.text);
            Swal.fire({
              icon: "error",
              title: "Failed to Send Message",
              text: "Oops, something went wrong!",
            });
          }
        );
    }
  }
  return (
    <form className="booknow-form row" ref={form} onSubmit={sendEmailBooking}>
      <div className="form-group col-md-6">
        <label htmlFor="name" className="form-label">
          Firstname
        </label>
        <input className="form-input" type="text" name="name" required />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="lastname" className="form-label">
          Lastname
        </label>
        <input className="form-input" type="text" name="lastname" required />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input className="form-input" type="email" name="email" required />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="tel" className="form-label">
          Phone Number
        </label>
        <input className="form-input" type="tel" name="tel" />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="bday" className="form-label-fix">
          Date of Birth
        </label>
        <input className="form-input-fix" type="date" name="bday" required />
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="country" className="form-label">
          Country
        </label>
        <input className="form-input" type="text" name="country" />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="check-in" className="form-label-fix">
          Check-in
        </label>
        <input className="form-input-fix" type="date" name="check-in" />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="check-out" className="form-label-fix">
          Check-out
        </label>
        <input className="form-input-fix" type="date" name="check-out" />
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="adults" className="form-label">
          No. of Adults
        </label>
        <input className="form-input" type="number" name="adults" />
      </div>
      <div className="form-group-fix col-md-6">
        <label htmlFor="accom-choice" className="form-label-fix">
          Accommodation
        </label>
        <input
          className="form-input-fix"
          type="text"
          id="accom-list"
          name="accom-list"
        />
        <select name="accom-list" id="accom-list">
          <option value="">Select Accommodation</option>
          <option value="Nana Beach">Nana Beach</option>
          <option value="Chumphon Cabana Resort">Chumphon Cabana Resort</option>
          <option value="Armonia Village">Armonia Village</option>
        </select>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="num-room" className="form-label">
          No. of Rooms
        </label>
        <input className="form-input" type="number" name="num-room" required />
      </div>
      <div className="form-group col-12">
        <fieldset className="cares" name="features">
          <div>
            <input
              type="checkbox"
              name="special-nursing-care"
              value="special-nursing-care"
            />
            <label htmlFor="special-nursing-care">
              Special nurse care (RN 8 hrs. per 1 shift) 2,000 THB
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              name="special-nursing-aid-care"
              value="Special-nursing-aid-care"
            />
            <label htmlFor="special-nursing-aid-care">
              Special nursing aid care (qualify) per 8 hrs. 1,000 THB
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              name="physical-therapist"
              value="physical-therapist"
            />
            <label htmlFor="physical-therapist">
              Physical therapist (PT) 2-3 hrs. per course 1,000 THB
            </label>
          </div>
          <div>
            <input type="checkbox" name="doctor-visit" value="doctor-visit" />
            <label htmlFor="doctor-visit">Doctor visit per time 500 THB</label>
          </div>
          <div>
            <input type="checkbox" name="activities" value="activities" />
            <label htmlFor="activities">
              Activities (tour, cooking class, yoga, pool exercise) 1,000 THB
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="transportation"
              value="transportation"
            />
            <label htmlFor="transportation">
              Transportation (round trip) per time 300 THB
            </label>
          </div>
        </fieldset>
      </div>

      <div id="contact-anchor"></div>
      <div className="form-group col-md-6">
        <input type="reset" value="RESET"></input>
      </div>
      <div className="form-group col-md-6">
        <input type="submit" value="SUBMIT"></input>
      </div>
    </form>
  );
}
