import "./TCH.css";
import { SlArrowRight } from "react-icons/sl";
import ScrollToTop from "./ScrollToTop";
import tch from "../img/tch.jpg";
import tch2 from "../img/tch2.jpg";
import tch3 from "../img/tch3.jpg";

export default function TCH() {
  return (
    <div className="tch-page">
      <ScrollToTop />
      <div className="stick-img"></div>
      <div className="container">
        <div className="row">
          <div className="tittle-box col-sm-6 col-md-4 col-lg-3" align="center">
            <h2>Thonburi-Chumphon Hospital</h2>
            <div className="line-break line-break2"></div>
          </div>
        </div>

        <div className="mainbox">
          <div className="row">
            <div className="col-md-7">
              <div className="col-text">
                <p>
                  Thonburi - Chumphon Hospital (TCH) was established in the
                  early 1990 and located on Chumphon - Ranong Road at Wang Phai,
                  Muang, Chumphon.
                </p>
                <p>
                  Today, with over 20 years experience behind us in the hospital
                  services sector, we have shown a commitment to the highest
                  standards. This has allowed us to achieve the hospital
                  accreditation standard of HA 1.
                </p>
                <p>
                  TCH is a secondary hospital with 100 beds and provides
                  comprehensive services and utilises modern technology to
                  assist staff and accredited medical practitioners to deliver
                  high standards of patient care and services.
                </p>
                <p>
                  TCH has a wide range of expert specialist teams and staff
                  members who work together to provide 24hrs of high level
                  specialised care for all patients.
                </p>
                <p>
                  TCH has multilingual staff of many levels who are able to
                  communicate in English competently, a doctor who can speak,
                  write, and read in many languages, such as; German, Italian,
                  and English. He also can communicate with other languages e.g.
                  French, Spanish, Scandinavian, Russian, Chinese, Burmese.
                </p>
                <p>
                  There is no doubt about our knowledges and experiences as a
                  leader in professional of medical services especially we do
                  non stop services for more than a quarter of a century. We
                  have many of senior staff of multi-level that understand how
                  the elderly people feel because they are also elderly people
                  but they have long time experience and still providing quality
                  medical services every day.
                </p>
              </div>
              <div className="link-btn">
                <a href="https://www.thonburi-chumphon.com">
                  Visit TCH Website{" "}
                  <span>
                    <SlArrowRight />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-md-5 ">
              <div className="col-img">
                <img className="tch-img1" src={tch} alt="tch1" />
                <img className="tch-img3" src={tch3} alt="tch3" />
                <img className="tch-img2" src={tch2} alt="tch2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="break-box"></div>
    </div>
  );
}
