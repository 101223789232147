import nnb from "../img/nnb.jpg";
import nnbPool from "../img/nnbpool.jpg";
import nnb3 from "../img/nnb3.jpg";
import nnb4 from "../img/nnb4.jpg";
import nnb5 from "../img/nnb5.jpg";
import nnb6 from "../img/nnb6.jpg";
import nnb7 from "../img/nnb7.jpg";
import nnb8 from "../img/nnb8.jpg";
import nnb9 from "../img/nnb9.jpg";
import nnb10 from "../img/nnb10.jpg";
import nnb11 from "../img/nnb11.jpg";
import nnb12 from "../img/nnb12.jpg";

import bcr1 from "../img/bcr1.jpg";
import bcr2 from "../img/bcr2.jpg";
import bcr3 from "../img/bcr3.jpg";
import bcr4 from "../img/bcr4.jpg";
import bcr5 from "../img/bcr5.jpg";
import bcr6 from "../img/bcr6.jpg";
import bcr7 from "../img/bcr7.jpg";
import av1 from "../img/av1.jpg";
import av2 from "../img/av2.jpg";
import av3 from "../img/av3.jpg";
import av4 from "../img/av4.jpg";
import av5 from "../img/av5.jpg";
import av6 from "../img/av6.jpg";
import av7 from "../img/av7.jpg";
import av8 from "../img/av8.jpg";
import av9 from "../img/av9.jpg";
import av10 from "../img/av10.jpg";
import av11 from "../img/av11.jpg";
import av12 from "../img/av12.jpg";

import beachFont from "../img/beach.svg";
import restaurant from "../img/meal2.png";
import bar from "../img/drink.svg";
import pool from "../img/pool.svg";
import garden from "../img/garden.svg";
import parking from "../img/park2.svg";
import massage from "../img/massage.svg";
import wifi from "../img/wifi.svg";
import laundry from "../img/laundry_icon.png";
import shuttle from "../img/shuttle.svg";
import airport from "../img/plane.svg";
import bicycle from "../img/bicycle.svg";
import fishing from "../img/fishing.svg";
import diving from "../img/diving.svg";
import pet from "../img/pet.svg";
import carHire from "../img/carhire.svg";
import gym from "../img/gym.svg";

export default function AccommodationData() {
  const resortData = [
    {
      name: "Nana Beach Resort",
      images: [
        nnb,
        nnbPool,
        nnb3,
        nnb4,
        nnb5,
        nnb6,
        nnb7,
        nnb8,
        nnb9,
        nnb10,
        nnb11,
        nnb12,
      ],
      info: [
        "This property is 1 minute walk from the beach. Situated in the heart of Thung Wua Lean Beach, Nana Beach Resort is 15 km from Chumpon City Centre and 22 km from Chumphon Airport. It boasts an outdoor swimming pool and a poolside bar. The hotel offers free Wi-Fi and parking.",
        "Each room at the hotel is equipped with air conditioning, a cable TV and a private balcony overlooking garden. The rooms also feature an en suite bathroom with shower facilities.",
        "There is a tour desk and ticketing service to help guests with sightseeing and travel arrangement.",
        "Boasting sea views, the beachfront restaurant offers a wide selection of Thai cuisine and fresh seafood.",
      ],
      facilities: [
        beachFont,
        restaurant,
        bar,
        pool,
        garden,
        parking,
        massage,
        wifi,
        laundry,
        shuttle,
        airport,
        bicycle,
        fishing,
        diving,
      ],
      facilitiesText: [
        "Beach Front",
        "Restaurant",
        "Bar",
        "Outdoor Pool",
        "Garden",
        "Private Parking",
        "Massage",
        "Wifi",
        "Laundry",
        "Shuttle Service",
        "Airport Shuttle",
        "Bicycle Rental",
        "Fishing",
        "Snorkelling & Diving",
      ],
      description:
        "This property is 1 minute walk from the beach. Situated in the heart of Thung Wua Lean Beach.",
      mapLink: "https://maps.app.goo.gl/uxwHw6ZZwyXDQrDc7",
      moreInfoLink: "nana-beach-resort",
      price: "$120",
      propertyLink: "nana-beach-resort",
    },
    {
      name: "Chumphon Cabana Resort",
      images: [bcr1, bcr2, bcr3, bcr4, bcr5, bcr6, bcr7],
      info: [
        "Chumphon Cabana Resort is located on the best part of white sandy Thung Wua Laen Beach, at the end of the road. Very private. 15 km. from city center and 25 km. from Chumphon Airport.",
        "The resort is sustainable environmental friendly, surrounded by jungle plants. Accommodation at the resort include 55 air-conditioned rooms and 18 superior bungalows. Other facilities include a swimming pool, massage services, and a shuttle bus service that runs to the bus and train station at the Chumphon and Koh Tao piers.",
        "Chumphon Cabana Resort is renowned for its PADI scuba diving courses and snorkeling trips that take guests to a variety of dive sites including Koh Tao, the Angtong National Marine Park, Ngam Island, and Ranped, Rankai. Another popular activity that guests seem to enjoy is a river rafting trek amidst a mountain range.",
      ],
      facilities: [
        beachFont,
        restaurant,
        bar,
        pool,
        garden,
        parking,
        massage,
        wifi,
        laundry,
        shuttle,
        airport,
        pet,
        fishing,
        diving,
      ],
      facilitiesText: [
        "Beach Front",
        "Restaurant",
        "Bar",
        "Outdoor Pool",
        "Garden",
        "Private Parking",
        "Massage",
        "Wifi",
        "Laundry",
        "Shuttle Service",
        "Airport Shuttle",
        "Pet Allowed",
        "Fishing",
        "Snorkelling & Diving",
      ],
      description:
        "The property is located on the best part of Thung Wua Lean beach, at the end of the road. Very private.",
      mapLink: "https://maps.app.goo.gl/UGu2tWesPSkmxZfR7", // Link for the map
      moreInfoLink: "chumphon-cabana-resort",
      price: "$120",
      propertyLink: "chumphon-cabana-resort",
    },
    {
      name: "Armonia Resort",
      images: [av1, av2, av3, av4, av5, av6, av7, av8, av9, av10, av11, av12],
      info: [
        "This property is 6 minutes walk from the beach. Armonia Village Resort and Spa is located in Chumphon, 1 km from Thung Wua Laen Beach, 16 km from the Chumphon’s city centre. It features an outdoor swimming pool, 2 dining options and bungalows with a private balcony.",
        "Overlooking the pool, bungalows come with modern Thai interiors and have plenty of natural light. All are fitted with a flat-screen TV, personal safe and an en suite bathroom. Toiletries and a hairdryer are included.",
        "Guests can work out at the fitness centre or play a game of tennis. Car and bicycle rental services are also available for those who wish to explore the area.",
        "The restaurant serves a variety of Mediterranean and Italian food. Pizza and refreshing beverages are offered at Beer Garden",
      ],
      facilities: [
        garden,
        restaurant,
        bar,
        pool,
        gym,
        parking,
        massage,
        wifi,
        laundry,
        shuttle,
        airport,
        carHire,
        pet,
        bicycle,
        fishing,
        diving,
      ],
      facilitiesText: [
        "Garden",
        "Restaurant",
        "Bar",
        "Outdoor Pool",
        "Fitness Centre",
        "Parking Garage",
        "Massage",
        "Wifi",
        "Laundry",
        "Shuttle Service",
        "Airport Shuttle",
        "Car Hire",
        "Pet Allowed",
        "Bicycle Renting",
        "Fishing",
        "Snorkelling & Diving",
      ],
      description:
        "The property is 6 minutes walk from Thung Wua Lean beach. It's a village with gym, big swimming pool, and spa.",
      mapLink: "https://maps.app.goo.gl/zjhxarwe8FpPxcsX7", // Link for the map
      moreInfoLink: "armonia-resort",
      price: "$120",
      propertyLink: "armonia-resort",
    },
  ];
  return resortData;
}
