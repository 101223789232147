import { TbArrowBackUp } from "react-icons/tb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AccommodationDetails.css";
import { Link, useParams } from "react-router-dom";
import React from "react";
import AccommodationData from "./AccommodationData";
import CustomiseStayForm from "./CustomiseStayForm";
import ScrollToTop from "./ScrollToTop";


export default function AccommodationDetails() {
  const { propertyLink } = useParams();
  const resortData = AccommodationData();

  const [slider1, setSlider1] = React.useState(null);
  const [slider2, setSlider2] = React.useState(null);

  // Filter the resort based on the propertyLink from the URL
  const selectedResort = resortData.find(
    (resort) => resort.propertyLink === propertyLink
  );

  if (!selectedResort) {
    // Resort not found for the given propertyLink
    return <div>Resort not found</div>;
  }

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: slider2,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: slider1,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
  };

  return (
    <div className="container resort-con">
      <ScrollToTop />
      <div>
        <div className="resort-title">
          <Link to="/accommodation" className="return-arrow">
            <TbArrowBackUp />
          </Link>
          <h1>{selectedResort.name}</h1>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="gallery-slide">
              <div className="slider-wrapper">
                <Slider {...settings1} ref={(slider) => setSlider1(slider)}>
                  {selectedResort.images.map((image, imgIndex) => (
                    <div key={imgIndex}>
                      <img src={image} alt={`Slide ${imgIndex + 1}`} />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="slider-wrapper thumbnail-wrap">
                <Slider {...settings2} ref={(slider) => setSlider2(slider)}>
                  {selectedResort.images.map((thumbnail, thumbIndex) => (
                    <div key={thumbIndex} className="thumbnail">
                      <img
                        src={thumbnail}
                        alt={`Thumbnail ${thumbIndex + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="resort-detail">
              {selectedResort.info.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
          <div className="col-md-5 customise-form-area">
            <CustomiseStayForm />
          </div>
        </div>
        <div className="facilities-con">
          <h2>FACILITIES</h2>
          <div className="row">
            {selectedResort.facilities.map((facility, index) => (
              <div key={index} className="col-md-2 col-sm-4 col-xs-6">
                <img className="icon" src={facility} alt={`${facility} icon`} />
                <div className="fac-text">{selectedResort.facilitiesText[index]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
