import "./Footer.css";
export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="right-reserved row">
      <div className="col-12">
        <p>
          © {getCurrentYear()} by Chumvej Elderly Care. All rights reserved.
        </p>
      </div>
    </div>
  );
}
